'use client';
import * as partners from '@/app/[lang]/(landing)/assets/partners';
import { cn } from '@/common/utils/cn';
import { motion } from 'framer-motion';
import Image from 'next/image';
import { useCallback, useState } from 'react';

const partnersArray = [
  <Image
    key='Helium'
    src={partners.helium}
    alt='Helium'
    className='max-w-[75px]'
  />,
  <Image
    key='Helius'
    src={partners.helius}
    alt='Helius'
    className='max-w-[88px]'
  />,
  <Image
    key='Latitude'
    src={partners.latitude}
    alt='Latitude'
    className='max-w-[92px]'
  />,
  <Image
    key='Squads'
    src={partners.squads}
    alt='Squads'
    className='max-w-[103px]'
  />,
  <Image key='DRiP' src={partners.drip} alt='DRiP' className='max-w-[89px]' />,
  <Image
    key='Andean'
    src={partners.andean}
    alt='Andean'
    className='max-w-[139px]'
  />,
];

export function Partners() {
  const [isTickerOn, setIsTickerOn] = useState(false);

  const tickerTapeCallbackRef = useCallback((node: HTMLDivElement | null) => {
    if (!node) return;
    if (node.scrollWidth >= 600) {
      node.classList.add('animate-ticker');
      setIsTickerOn(true);
    } else {
      node.classList.remove('animate-ticker');

      setIsTickerOn(false);
    }
  }, []);

  return (
    <div className='w-full max-w-full lg:max-w-4xl overflow-hidden md:max-lg:px-4 lg:[mask-image:_linear-gradient(to_right,transparent,#000_10%,#000_90%,transparent_100%)]'>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 100 }}
        transition={{ type: 'tween', duration: 0.25, ease: 'easeOut' }}
        className='flex items-center overflow-hidden h-[64px]'
      >
        <div
          ref={tickerTapeCallbackRef}
          className={cn('min-w-max flex *:shrink-0 items-center gap-x-9 pr-9')}
        >
          {partnersArray}
        </div>
        {isTickerOn && (
          <>
            <div className='animate-ticker min-w-max flex *:shrink-0 items-center gap-x-9 pr-9'>
              {partnersArray}
            </div>
          </>
        )}
      </motion.div>
    </div>
  );
}
