import { ReactNode } from 'react';
import { toast, Toaster, ExternalToast } from 'sonner';

import { AlertCircle, AlertTriangle } from '../../../icons/Alert';
import { CheckCircle } from '../../../icons/Check';
import { clsx } from '../../../utils/clsx';

const APP_TOAST_ID = 'app-toast';

const toastModeConfig = {
  default: {
    icon: null,
  },
  warning: {
    icon: <AlertTriangle />,
  },
  error: {
    icon: <AlertCircle />,
  },
  success: {
    icon: <CheckCircle />,
  },
};

export default function AppToastContainer() {
  return <Toaster position='bottom-left' />;
}

export interface AppToastProps {
  text: string;
  mode: keyof typeof toastModeConfig;
  title?: string;
  children?: ReactNode;
}

export const AppToast = ({ text, mode, title, children }: AppToastProps) => {
  const { icon } = toastModeConfig[mode];

  return (
    <div style={{ borderRadius: '1px' }} className='flex gap-3'>
      {icon !== null && <div className='h-5 w-5 flex-none'>{icon}</div>}
      <div className={clsx('!rounded-br-2xl text-[14px] font-[400]')}>
        {title && <div className='mb-[0.05rem] font-medium'>{title}</div>}
        {text}
        {children}
      </div>
    </div>
  );
};

interface ShowToastArgs extends ExternalToast {
  text: string;
  mode: keyof typeof toastModeConfig;
  title?: string;
  actions?: ReactNode;
  duration?: number;
}

export const showToast = ({
  text,
  mode,
  title,
  actions,
  ...rest
}: ShowToastArgs) => {
  return toast(
    <AppToast text={text} mode={mode} title={title}>
      {actions}
    </AppToast>,
    {
      id: APP_TOAST_ID,
      ...rest,
    },
  );
};
