import { SVGProps } from 'react';

export default function Alert({
  color,
  ...rest
}: SVGProps<SVGSVGElement> & { color?: string }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='16'
      fill='none'
      viewBox='0 0 16 16'
      {...rest}
    >
      <path
        stroke={color ?? '#F85858'}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
        d='M8 10.8V8m0-2.8h.007M1 5.566v4.868c0 .171 0 .257.02.338a.7.7 0 00.083.202c.043.07.104.131.225.252l3.446 3.446c.121.121.182.182.252.225.063.038.131.066.203.084.08.019.166.019.337.019h4.868c.171 0 .257 0 .338-.02a.7.7 0 00.202-.083c.07-.043.131-.104.252-.225l3.446-3.446c.121-.121.182-.182.225-.252a.7.7 0 00.084-.203c.019-.08.019-.166.019-.337V5.566c0-.171 0-.257-.02-.337a.701.701 0 00-.083-.203c-.043-.07-.104-.131-.225-.252l-3.446-3.446a1.497 1.497 0 00-.252-.225.7.7 0 00-.203-.084C10.691 1 10.605 1 10.434 1H5.566c-.171 0-.257 0-.337.02a.7.7 0 00-.203.083c-.07.043-.131.104-.252.225L1.328 4.774c-.121.121-.182.182-.225.252a.7.7 0 00-.084.203C1 5.309 1 5.395 1 5.566z'
      />
    </svg>
  );
}

export function AlertCircle({
  color,
  ...rest
}: SVGProps<SVGSVGElement> & { color?: string }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='20'
      fill='none'
      viewBox='0 0 20 20'
      {...rest}
    >
      <g clipPath='url(#clip0_7771_99163)'>
        <path
          stroke={color ?? '#D92D20'}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.667'
          d='M10 6.667V10m0 3.333h.008M18.333 10a8.333 8.333 0 11-16.666 0 8.333 8.333 0 0116.666 0z'
        />
      </g>
      <defs>
        <clipPath id='clip0_7771_99163'>
          <path fill='#fff' d='M0 0H20V20H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}

export function AlertTriangle({
  color,
  ...rest
}: SVGProps<SVGSVGElement> & { color?: string }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='20'
      fill='none'
      viewBox='0 0 20 20'
      {...rest}
    >
      <path
        stroke={color ?? '#DC6803'}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.667'
        d='M10 7.5v3.333m0 3.334h.008M8.846 3.243L1.992 15.082c-.38.657-.57.985-.542 1.254a.833.833 0 00.338.588c.22.16.599.16 1.358.16h13.708c.759 0 1.138 0 1.357-.16a.834.834 0 00.339-.588c.028-.27-.162-.597-.542-1.254L11.154 3.243c-.38-.654-.569-.981-.816-1.091a.833.833 0 00-.677 0c-.247.11-.436.437-.815 1.091z'
      />
    </svg>
  );
}
