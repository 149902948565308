import { SVGProps } from 'react';

export default function Check({ color, ...rest }: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' {...rest}>
      <path
        fill={color}
        d='M21 7L9 19l-5.5-5.5 1.41-1.41L9 16.17 19.59 5.59 21 7z'
      />
    </svg>
  );
}

export function CheckCircle({
  color,
  ...rest
}: SVGProps<SVGSVGElement> & { color?: string }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='20'
      fill='none'
      viewBox='0 0 20 20'
      {...rest}
    >
      <g clipPath='url(#clip0_7771_99221)'>
        <path
          stroke={color ?? '#039855'}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.667'
          d='M6.25 10l2.5 2.5 5-5m4.583 2.5a8.333 8.333 0 11-16.666 0 8.333 8.333 0 0116.666 0z'
        />
      </g>
      <defs>
        <clipPath id='clip0_7771_99221'>
          <path fill='#fff' d='M0 0H20V20H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}
